import Send from '../../user.client.js'
const mode = process.env.VUE_APP_MODE
const addUrl = '/user'
export default {
  getProfileList (userId, userCtrCd) {
    return Send({
      url: addUrl + '/myinfo/profiles',
      method: 'get',
      params: {
        userId: userId,
        cstCd: sessionStorage.getItem('cstCd'),
        userCtrCd: userCtrCd
      }
    })
  },
  getSimpleProfileList (userId) {
    return Send({
      url: addUrl + '/myinfo/simple-profiles',
      method: 'get',
      params: {
        userId: userId,
        cstCd: sessionStorage.getItem('cstCd')
      }
    })
  },
  getProfile (queryParams) {
    return Send({
      url: addUrl + '/myinfo/profile' + queryParams,
      method: 'get',
      params: {}
    })
  },
  getCountryCodeList () {
    return Send({
      url: addUrl + '/myinfo/countryCodes',
      method: 'get',
      params: {}
    })
  },
  getAlarmCodeList () {
    return Send({
      url: addUrl + '/myinfo/alarmCodes',
      method: 'get',
      params: {}
    })
  },
  getChargeCodeList () {
    return Send({
      url: addUrl + '/myinfo/chargeCodes',
      method: 'get',
      params: {}
    })
  },
  saveProfile (data) {
    return Send({
      url: addUrl + '/myinfo/profile',
      method: 'post',
      data: data
    })
  },
  getSchSubsList (userId) {
    return Send({
      url: addUrl + '/myinfo/sch-subs?userId=' + userId,
      method: 'get',
      params: {}
    })
  },
  deleteSchSubsList (data) {
    return Send({
      url: addUrl + '/myinfo/sch-subs',
      method: 'delete',
      data: data
    })
  },
  checkDuplicationProfileName (params) {
    return Send({
      url: addUrl + '/myinfo/check-profile-name',
      method: 'get',
      params: params
    })
  }
}

<template>
  <div class="header">
    <!-- header -->
    <div class="inner_header">
      <h1 class="logo"><a href="#" @click.prevent="$router.push('/').catch(()=>{});">e-kmtc</a></h1>
      <div class="wrap_gnb">
        <ul class="gnb">
          <li v-for="(vo, idx) in menus" :key="'top_menu_' + idx">
            <a v-if="vo.funcOpenType == null" :href="`#${vo.path}`" @click.prevent="$router.push(vo.path).catch(()=>{});" :class="{ on: vo.on }">{{ vo.title }}</a>
            <a v-else @click.prevent="menuOpen(vo.funcOpenType, vo.path)" class="cursor_pointer" :class="{ on: vo.on }">{{ vo.title }}</a>
          </li>
          <li v-if="serviceLang === 'KOR'">
            <a href="https://apiportal.ekmtc.com/index?lang=KO" target="_blank">API</a>
          </li>
          <li v-else>
            <a href="https://apiportal.ekmtc.com/index?lang=EN" target="_blank">API</a>
          </li>
        </ul>
      </div>
      <e-header-util @sitemap="fnSitemap"></e-header-util>
    </div>
    <div class="sitemap">
      <div class="sitemap_header">
        <button type="button" class="sitemap_close" @click.prevent="fnSitemap(false)"><span class="offscreen">사이트맵 닫기</span></button>
      </div>
      <div class="sitemap_content">
        <div class="sm_con">
          <ul>
            <e-sitemap-menu :menu="menusAll[0]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[3]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[6]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusCp[0]" @click="fnSitemap(false)"></e-sitemap-menu>
            <li v-if="auth.login && memberDetail.fwdrCstCd == '06'">
              <div class="tit" style="cursor: pointer" @click="openVendorPortal">Vendor Portal</div>
            </li>
            <li v-if="auth.login && memberDetail.fwdrCstCd == '07'">
              <div class="tit" style="cursor: pointer" @click="openMoveUp">Movement Upload</div>
            </li>
            <li v-if="auth.login && memberDetail.fwdrCstCd == '08'">
              <div class="tit" style="cursor: pointer" @click="openOffHire">OFF-HIRE EOR</div>
            </li>
            <li v-if="staffFlag && serviceLang === 'KOR'">
              <div class="tit" style="cursor: pointer" @click="openCard">전자연하장</div>
            </li>
          </ul>
        </div>
        <div class="sm_con">
          <ul>
            <e-sitemap-menu :menu="menusAll[1]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[4]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[7]" @click="fnSitemap(false)"></e-sitemap-menu>
          </ul>
        </div>
        <div class="sm_con">
          <ul>
            <e-sitemap-menu :menu="menusAll[2]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[5]" @click="fnSitemap(false)"></e-sitemap-menu>
            <e-sitemap-menu :menu="menusAll[8]" @click="fnSitemap(false)"></e-sitemap-menu>
          </ul>
        </div>
      </div>
    </div>
    <!-- header // -->
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import EHeaderUtil from '@/components/layout/EHeaderUtil'
import ESitemapMenu from '@/components/layout/ESitemapMenu'
import topmenu01_03 from '@/router/topmenu01_03'
import topmenu04_06 from '@/router/topmenu04_06'
import topmenu07_09 from '@/router/topmenu07_09'
import topmenu_cp from '@/router/topmenu_cp'
import cpCommon from '@/api/rest/cp/cpCommonInfo'
import schedule from '@/api/rest/schedule/schedule'
export default {
  name: 'Header',
  components: { EHeaderUtil, ESitemapMenu },
  data () {
    return {
      menusAll: [],
      menusCp: [],
      menus: [],
      cpAdmin: 'N',
      cpUser: 'N',
      kmtcSpotUserYn: 'N',
      kmtcSpotNegoYn: 'N'
    }
  },
  computed: {
    ...rootComputed,
    serviceLang () {
      return this.$store.state.auth.serviceLang
    },
    staffFlag () {
      return this.$store.getters['auth/isStaff']
    }//,
    //menusCp () {
    //  let menusCp = []
    //  menusCp = [
    //    ...(this.staffFlag ? topmenu_cp_01 : [])
    //  ]
    //  return menusCp
    //}
  },
  watch: {
    $route (to, from) {
      this.setMenuOn(to.path)
    },
    serviceLang: function () {
      this.menus = this.setMenus()
    }
  },
  // created에 async, await 사용은 상단 헤더 메뉴에 kmtc on now 메뉴 노출을 위해 필요함.
  // async, await 없이 조회시 getKmtcSpotTarget 설정값 세팅이 안되어 kmtc on now 메뉴 노출이 되지 않음.
  async created () {
    this.setMenuOn(this.$route.path)
    this.menusAll = [
      ...topmenu01_03,
      ...topmenu04_06,
      ...topmenu07_09
    ]

    //if (this.staffFlag) {
    //  this.menusCp = [...topmenu_cp]
    //}
    // CP Role 에 맞게 CP 메뉴를 설정
    cpCommon.cpRole().then(response => {
      this.cpAdmin = response.data.cpAdmin
      this.cpUser = response.data.cpUser
      if (this.staffFlag) {
        if (this.cpAdmin === 'Y') {
          this.menusCp = [...topmenu_cp]
        } else {
          if (this.cpUser === 'Y') {
            this.menusCp = [...topmenu_cp]
            this.menusCp[0].children[4].meta.sitemapHide = true
          }
        }
      }
    }).catch(err => {
      console.log(err)
    })
    const staffFlag = this.$store.getters['auth/isStaff']
    console.log('@@@@ create staffFlag: ', staffFlag)

    if (this.auth.login) {
      //menuCD 01은 로그를 남기지 않음. kmtc on 대상 여부만 조회
      await schedule.getKmtcSpotTarget('01').then((response) => {
        this.kmtcSpotUserYn = response.data[0].kmtcSpotUserYn
        this.kmtcSpotNegoYn = response.data[0].kmtcSpotNegoYn
      })
    }

    this.menus = this.setMenus()
  },
  methods: {
    ...rootMethods,
    setMenus () {
      let menus = []

      menus = [
        { id: 0, title: this.$t('menu.MENU01.000'), path: '/booking-new', on: false }, // 새로운 부킹
        { id: 1, title: this.$t('menu.MENU02.000'), path: '/schedule/leg', on: false }, // 스케줄
        { id: 2, title: this.$t('menu.MENU03.000'), path: '/working/progress', on: false }, // 진행중인업무
        { id: 4, title: this.$t('menu.MENU05.000'), path: '/shipping-cost/inquiry', on: false }, // 운임
        { id: 5, title: this.$t('menu.MENU06.000'), path: '/calcIssue/calcIssueMain', on: false, funcOpenType: 'calcIssue' }, // 정산/발급
        { id: 6, title: this.$t('menu.MENU07.000'), path: '/cargo-tracking', on: false } // 화물추적

      ]

      // KMTC ON 대상 유저 && KMTC ON 회원등급 D등급 대상 노출(C등급인 경우 N으로 노출 안됨)
      if (this.kmtcSpotUserYn === 'Y' && this.kmtcSpotNegoYn === 'Y') {
        menus = [
          { id: 0, title: this.$t('menu.MENU01.000'), path: '/booking-new', on: false }, // 새로운 부킹
          { id: 1, title: this.$t('menu.MENU02.000'), path: '/schedule/leg', on: false }, // 스케줄
          { id: 2, title: this.$t('menu.MENU03.000'), path: '/working/progress', on: false }, // 진행중인업무
          { id: 4, title: this.$t('menu.MENU05.000'), path: '/shipping-cost/inquiry', on: false }, // 운임
          { id: 5, title: this.$t('menu.MENU06.000'), path: '/calcIssue/calcIssueMain', on: false, funcOpenType: 'calcIssue' }, // 정산/발급
          { id: 6, title: this.$t('menu.MENU07.000'), path: '/cargo-tracking', on: false }, // 화물추적
          //{ id: 7, title: 'KMTC ON', path: '/kmtc-spot?menuCd=04', on: false } // KMTC ON
          { id: 7, title: 'KMTC ON NOW', path: '/kmtc-premium', on: false } // KMTC ON NOW
        ]
      }
      return menus
    },
    menuOpen (openType, url) {
      if (openType === 'calcIssue') { // 정산/발행
        this.$store.commit('settle/updateTabIndex', null) // 저장된 탭index 삭제
        this.$router.push(url).catch(() => {})
      }
    },
    setMenuOn (fullPath) {
      let path = '/'

      if (fullPath.includes('/')) {
        const arrPath = fullPath.split('/')
        if (arrPath.length >= 1) {
          path = '/' + arrPath[1]
        }
      }

      this.menus.forEach(menu => {
        menu.on = path !== '/' && (path === menu.path || menu.path.indexOf(path) === 0)
      })
    },
    fnSitemap (show) {
      if (show) {
        $('.sitemap').addClass('active')
        $('.wrap').append('<div class="dimmed"></div>')
        $('.dimmed').fadeIn(600)
        $('body,html').css('overflow', 'hidden')

        this.$el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.fnSitemapHide)
      } else {
        $('.sitemap').removeClass('active')
        $('.dimmed').remove()
        $('body,html').removeAttr('style')

        this.$el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.fnSitemapHide)
      }
    },
    fnSitemapHide () {
      this.fnSitemap(false)
    },
    stopProp (e) {
      e.stopPropagation()
    },
    openVendorPortal () {
      const form = $('<form>').attr({
        action: process.env.VUE_APP_VENDOR_PORTAL_URL,
        method: 'post',
        target: '_blank'
      }).appendTo(document.body)

      const auth = this.auth
      const memberDetail = this.memberDetail

      $('<input>').attr({ name: 'user_id' }).appendTo(form).val(auth.orgUserId ?? auth.userId)
      $('<input>').attr({ name: 'cst_cat_cd' }).appendTo(form).val(memberDetail.fwdrCstCd)

      form.submit()
      form.remove()
    },
    openMoveUp () {
      this.openVendorPortal()
    },
    openOffHire () {
      this.openVendorPortal()
    },
    openCard () {
      this.$router.push({ name: 'sendNewYearCard' }).catch(() => {})
      $('.sitemap').removeClass('active')
      $('.dimmed').remove()
      $('body,html').removeAttr('style')

      this.$el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.fnSitemapHide)
    }
  }
}
</script>

<style>

.dimmed {
  z-index: 901 !important;
}
</style>
